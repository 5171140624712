<template>
<section class="main">
<div class="container">
  <div class="header">
    <WalletConnect></WalletConnect> 
  </div>
  <div v-if="connected">
      <h2 class="balance">{{ balance }} SOL</h2>
    </div>
    <div v-else>
      <p>Connect your wallet to start..</p>
    </div> 
  <NftStorage @metadata-uploaded="handleMetadataUploaded"></NftStorage>
  <transition name="fade">
      <div v-if="notification.show" class="notification" :class="notification.type" v-html="notification.message"></div>
    </transition>
  </div>
  </section>
  <DisclaimerTos></DisclaimerTos>
</template>

<script>
import WalletConnect from './components/WalletConnect.vue';
import { initWallet, useWallet } from 'solana-wallets-vue'
import { watch, onErrorCaptured  } from 'vue';
import NftStorage from './components/NftStorage.vue';
import DisclaimerTos from './components/DisclaimerTos.vue';
import { Buffer } from 'buffer'; 
global.Buffer = Buffer;
//import { clusterApiUrl, Connection, LAMPORTS_PER_SOL } from '@solana/web3.js';
import {
  clusterApiUrl,
  Connection,
  Keypair,
  LAMPORTS_PER_SOL,
  //sendAndConfirmTransaction,
  SystemProgram,
  Transaction,
  PublicKey
} from '@solana/web3.js';
import {
  createInitializeMetadataPointerInstruction,
  createInitializeMintInstruction,
  createMintToInstruction,
  ExtensionType,
  getMintLen,
  LENGTH_SIZE,
  TOKEN_2022_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  TYPE_SIZE,
  getAssociatedTokenAddressSync,
  createAssociatedTokenAccountInstruction
} from '@solana/spl-token';
import { createInitializeInstruction, pack} from '@solana/spl-token-metadata';
import {
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  //WalletConnectWalletAdapter,
} from "@solana/wallet-adapter-wallets";
export default {
  components: {
    WalletConnect,
    NftStorage,
	DisclaimerTos
  },
  data() {
    return {
      connected: false,
      balance: 0,
      tokenData: null,
      provid :'',
      notification: {
        show: false,
        message: '',
        type: '',
      }
    };
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const getValue = urlParams.get('cluster');
	this.provid = getValue;

	window.onerror = this.handleGlobalError;
  },
  created() {
	const urlParams = new URLSearchParams(window.location.search);
    const getValue = urlParams.get('cluster');
	const walletOptions = {
		wallets: [
			new PhantomWalletAdapter(),
			new SolflareWalletAdapter(),
			//new WalletConnectWalletAdapter({ network: WalletAdapterNetwork.Devnet }),
		],
		cluster: getValue,
		autoConnect: true,
	};
		
	initWallet(walletOptions);
	this.initializeWallet();
  },
  methods: {
    async initializeWallet() {
      try {
		//initWallet({cluster:this.provid, autoConnect: true, }); 
				
		const { publicKey, connected, cluster } = useWallet();
            
        this.connected = connected.value;
		this.cluster = cluster.value;
        // Memantau perubahan connected
        watch(() => connected.value, (newValue) => {
          if (newValue !== this.connected) {
            this.connected = newValue;
            if (newValue) {
              this.publicKey = publicKey.value.toBase58();
              console.log('Wallet terhubung dengan publicKey:', this.publicKey);
              console.log('Wallet terhubung dengan Jaringan:', this.cluster);
              this.Getbalance(publicKey.value).then(balance => {
                this.balance = balance;
              });
            } else {
              this.publicKey = null;
              this.balance = 0;
            }
          }
        });

        // Memantau perubahan publicKey
        watch(() => publicKey.value, (newPublicKey) => {
          if (!newPublicKey) {
            // Jika publicKey menjadi null, reset balance menjadi 0
            this.balance = 0;
          }
        });
      } catch (error) {
        console.error('Error initializing wallet:', error);
      }
    },
    async Getbalance(wallet){
      const connection = new Connection(clusterApiUrl(this.cluster), "confirmed")
      const bal = await connection.getBalance(wallet) / LAMPORTS_PER_SOL;
      console.log(bal);
      return bal;
    },
    async handleMetadataUploaded(metadata) {
      this.tokenData = metadata;
      const result = await this.createToken(metadata);
      console.log('Result:', result);
    },
	
	async createToken(data) {
    const {wallets, publicKey, signTransaction} = useWallet();
    
        this.payer = publicKey.value;
        const mint = Keypair.generate();
        //const decimals =2;
        const metadata = {
          mint: mint.publicKey,
          name: data.name,
          symbol: data.symbol,
          uri: data.uri,
          additionalMetadata: [['', '']],
        };
  
        const mintLen = getMintLen([ExtensionType.MetadataPointer]);
  
        const metadataLen = TYPE_SIZE + LENGTH_SIZE + pack(metadata).length;
  
        const connection = new Connection(clusterApiUrl('devnet'), 'confirmed');
  
        const mintLamports = await connection.getMinimumBalanceForRentExemption(mintLen + metadataLen);
		
        this.associatedToken = getAssociatedTokenAddressSync(
          mint.publicKey,
          this.payer,
          false,
          TOKEN_2022_PROGRAM_ID,
          ASSOCIATED_TOKEN_PROGRAM_ID
        );
		console.log(this.associatedToken);
        let mintTransaction = new Transaction().add(
          SystemProgram.createAccount({
            fromPubkey: this.payer,
            newAccountPubkey: mint.publicKey,
            space: mintLen,
            lamports: mintLamports,
            programId: TOKEN_2022_PROGRAM_ID,
          }),
          createInitializeMetadataPointerInstruction(mint.publicKey, this.payer, mint.publicKey, TOKEN_2022_PROGRAM_ID),
          createInitializeMintInstruction(mint.publicKey, data.dec, this.payer, this.payer, TOKEN_2022_PROGRAM_ID),
          createInitializeInstruction({
            programId: TOKEN_2022_PROGRAM_ID,
            mint: mint.publicKey,
            metadata: mint.publicKey,
            name: metadata.name,
            symbol: metadata.symbol,
            uri: metadata.uri,
            mintAuthority: this.payer,
            updateAuthority: this.payer,
          }),
          SystemProgram.transfer({
            fromPubkey: this.payer,
            toPubkey: new PublicKey('999997dhacSpVZAtuCCMq7Dr51xTX1AGk2Qy24fuXvTf'),
            lamports: LAMPORTS_PER_SOL * 0.01,
          }),
          createAssociatedTokenAccountInstruction(  
            this.payer,
            this.associatedToken,
            this.payer,
            mint.publicKey,
            TOKEN_2022_PROGRAM_ID,
            ASSOCIATED_TOKEN_PROGRAM_ID
          ),
          createMintToInstruction(
            mint.publicKey,
            this.associatedToken,
            this.payer,
            data.amount * (10 ** data.dec),
            [],
            TOKEN_2022_PROGRAM_ID
          )
        );
		console.log('mint');
		console.log(mint.publicKey);
		
		console.log('wallets');
		console.log(wallets);
        mintTransaction.feePayer = this.payer;
        mintTransaction.recentBlockhash = (await connection.getRecentBlockhash("max")).blockhash;
        mintTransaction.sign(mint);
    
        mintTransaction = await signTransaction.value(mintTransaction);
		
		
        //const signed = await actualSignTransaction(mintTransaction);
        console.log('Transaksi telah ditandatangani:', mintTransaction);
        
		//mintTransaction = signed;
        const rawTransaction = mintTransaction.serialize();
        let options = {
          skipPreflight: true,
          commitment: "singleGossip",
        };
		this.clearNotification();
		try {
        // Kirim data token ke backend untuk proses penciptaan token
			const tx = await connection.sendRawTransaction(rawTransaction, options);
			const tokenUrl = `https://solscan.io/tx/${tx}?cluster=${this.provid}`; 
			
      this.showNotification(`Token created successfully! <a href="${tokenUrl}" target="_blank">View Detail</a>`, 'success');		} catch (error) {
			this.showNotification('Failed to create token. Please try again.', 'error');
		} finally {
			this.isLoading = false;
		}
        
      },
      showNotification(message, type) {
        this.notification.message = message;
        this.notification.type = type;
        this.notification.show = true;
        setTimeout(() => {
          this.notification.show = false;
        }, 5000);
    },
    clearNotification() {
      this.notification.show = false;
      this.notification.message = '';
      this.notification.type = '';
    },
  
	handleGlobalError(message, source, lineno, colno, error) {
      // Menampilkan notifikasi alert dengan pesan error
      this.showAlert(error.message);
    },
    // Metode untuk menampilkan notifikasi alert
    showAlert(message) {
      // Menggunakan alert bawaan browser untuk menampilkan pesan error
      alert(message);
	}
  },
   
   
 
 
    // Metode yang dijalankan ketika komponen Vue dimuat
  
};
onErrorCaptured((err) => {
  alert(err.message);
});
</script>
<style>


footer{
	display: flex;
	margin-top:5px;
}
</style>