<template>
  <section>
    <h2>Create Token</h2>
	<form @submit.prevent="uploadImageWithMetadata">
    <div class="form-group">
    <label>Token Name :</label>
    <input type="text" v-model="name" placeholder="Meme Token" required>
    </div>
    <div class="form-group">
    <label>Token Symbol :</label>
    <input type="text" v-model="symbol" placeholder="MMT" required>
    </div>
    <div class="form-group">
    <label>Decimals : (0 - 9)</label>
    <input type="number" v-model="dec" min="0" max="9" placeholder="2" required>
    </div>
    <div class="form-group">
    <label>Mint Amount : (max : 9e15 decimal include)</label>
    <input type="number" v-model="amount" placeholder="21000000000">
    </div>
    <div class="form-group">
    <label>Description :</label>
    <textarea v-model="description" rows="2"></textarea>
    </div>
    <div class="form-group">
    <label>Token Logo :</label>
    <input type="file" @change="handleFileUpload">
    </div>
	<div class="form-btn">
    <button type="submit" class="btn" :disabled="isLoading">CREATE<span v-if="isLoading" class="spinner"></span></button>
	</div>
	</form>
  </section>

</template>
  
<script>
import { NFTStorage, Blob } from 'nft.storage';
export default {
  data() {
    return {
      imageFile: null,
      metadataFile: null,
      name: '',
      symbol: '',
      description: '',
      uri:'',
      dec: '',
      amount: '',
      cids: null,
      isLoading: false,
      notification: {
        show: false,
        message: '',
        type: '',
      },
    };
  },
  methods: {
    handleFileUpload(event) {
      this.imageFile = event.target.files[0];
      //this.imageName = event.target.files[0].name;
    },
    async uploadImageWithMetadata() {
      if (!this.imageFile) {
        alert('Please select an image first.');
        return;
      }

      const endpoint = 'https://api.nft.storage'; // the default
      const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDA4MEVBZURlQjc5NzU4MjI0RDkyMUU2MmNlMDJhNjE1ZTcxMjgzYTMiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTcxMzc3ODU4MDM3NSwibmFtZSI6InNscHRvb2xzIn0.yrsT87zglMOVYLKnxdvJq7AgM4WQA3lZlIpOIaXkwpU';
      const storage = new NFTStorage({ endpoint, token });

      const cid = await storage.storeBlob(new Blob([this.imageFile]));
      
      const metadata = {
        name: this.name,
        symbol: this.symbol,
        description: this.description,
        image: "https://" + cid + ".ipfs.nftstorage.link/"
      };

      this.metadataFile = new File([JSON.stringify(metadata)], 'metadata.json', { type: 'application/json' });

      const metadataCid = await storage.storeBlob(new Blob([this.metadataFile]));

      this.uri = "https://" + metadataCid +".ipfs.nftstorage.link/";
      this.cids = { image: cid, metadata: metadataCid };
      this.$emit('metadata-uploaded', { name: this.name, symbol: this.symbol, description: this.description, uri: this.uri, cids: this.cids, dec:this.dec, amount:this.amount });
    },
	
  }
};
</script>
  
<style scoped>

.main {
	position: relative;
	z-index: 2;
	border-top: 1px solid #e6e6e6;
}
input, textarea, button {
    position: relative;
	width: 100%;
	font-size: 20px;
	font-family: inherit;
	font-weight: inherit;
	color: inherit;
	padding: 6px;
	border: 0;
	box-shadow: inset 0 -1px 5px 0 rgba(0, 0, 0, 0);
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.main input, textarea {border-bottom: 1px solid #d9d7d7;}
label{
	font-size: 16px;
	line-height: 1.4em;
}
.form-btn{
	margin-top:5px;
	display: flex;
	justify-content: Center;
}
.btn{
	background:#4f46e5;
	cursor: pointer;
	font-size: 14px;
	font-weight: 600;
	height: 70px;
	width: 70px;
	color: white;
	border-radius: 50%;
	box-shadow: 0 2px 4px darkslategray;
	transition: all 0.2s ease;
}
.btn:hover{
	background-color: #65b9e6;
	box-shadow: 0 0 0 10px #FFF, 0 0 0 13px #4f46e5, 0 0 0 20px #FFF, 0 0 0 23px #4f46e5;
    animation: btn 1s linear 0s infinite ;
}
.btn:active {
  box-shadow: 0 0 2px darkslategray;
  transform: translateY(2px);
}
</style>
