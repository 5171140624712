<template>
  <div class="right">
    <div class="swv-dropdown">
      <button class="swv-button"
              :style="{ pointerEvents: dropdownOpened ? 'none' : 'auto', background: devnet ? '#1f2937' : 'blue' }"
              :aria-expanded="dropdownOpened"
              title="Provider"
              @click="openDropdown">
        <p>{{ devnet ? 'Devnet' : 'Mainnet' }}</p>
      </button>
      <ul aria-label="dropdown-list"
          class="swv-dropdown-list"
          :class="{ 'swv-dropdown-list-active': dropdownOpened }"
          ref="dropdownPanel"
          role="menu">
        <li @click="switchToMainnet"
            class="swv-dropdown-list-item"
            role="menuitem">
          Mainnet-beta
        </li>
        <li @click="switchToDevnet"
            class="swv-dropdown-list-item"
            role="menuitem">
          Devnet
        </li>
      </ul>
    </div>
  </div>
  <wallet-multi-button></wallet-multi-button>
</template>

<script>
//import { ref } from "vue";
import { WalletMultiButton } from "solana-wallets-vue";
import { onClickOutside } from "@vueuse/core";

export default {
  components: {
    WalletMultiButton
  },
  data() {
    return {
      devnet: false,
      dropdownOpened: false,
    };
  },
  methods: {
    switchToMainnet() {
      window.location.replace('?cluster=mainnet-beta');
    },
    switchToDevnet() {
      window.location.replace('?cluster=devnet');
    },
    openDropdown() {
      this.dropdownOpened = true;
    },
    closeDropdown() {
      this.dropdownOpened = false;
    }
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const getValue = urlParams.get('cluster');
    if (getValue === 'devnet') {
      this.devnet = true;
    }

    onClickOutside(document.head, this.closeDropdown);
  }
};
</script>



<style scoped>
.mainnet-beta{
	background-color: var(--swv-button-hover-background-color);
}
.devnet{
	background-color: var(--swv-button-hover-background-color);
}
select{
	background-color: #4f46e5;
	color :var(--swv-button-text-color);
    cursor: pointer;
	display: flex;
	align-items: center;
	font-family: 'DM Sans', 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-size: 16px;
	font-weight: 600;
	height: 48px;
	line-height: 48px;
	padding: 0 18px;
	border-radius: var(--swv-button-radius);
	float: right;
	position: relative;
	}
option{
  position: absolute;
  z-index: 99;
  display: grid;
  grid-template-rows: 1fr;
  grid-row-gap: 10px;
  padding: 10px;
  top: 100%;
  right: 0;
  margin: 0;
  list-style: none;
  background: var(--swv-dropdown-background-color);
  color: var(--swv-dropdown-text-color);
  border-radius: var(--swv-dropdown-radius);
  box-shadow: var(--swm-dropdown-shadow);
  opacity: 0;
  visibility: hidden;
  transition: opacity 200ms ease, transform 200ms ease, visibility 200ms;
  font-family: 'DM Sans', 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.right{
	float:right;
}

</style>
