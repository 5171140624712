<template>
  <div class="disclaimer">
    <h1>Disclaimer</h1>
    <p>This application is a decentralized application (DApp) designed to create tokens on the Solana network. You must understand that the use of this application is your own decision, and you are fully responsible for any actions you take through this application.</p>
    <p><strong>1. Network Fees:</strong> Every transaction you make through this application will incur network fees according to the standard Solana network fees. Additionally, the creation of new tokens will incur an additional network fee of 0.01 SOL for service fees.</p>
    <p><strong>2. Technical Risks:</strong> The use of this application may be subject to technical risks, including but not limited to errors in the code, cyber-attacks, or network disruptions. We are not liable for any losses or damages caused by these technical risks.</p>
    <p><strong>3. Prohibition of Use for Criminal Activities:</strong> Users are strictly prohibited from using this application for criminal activities, including but not limited to creating tokens for fraudulent projects. However, since our DApp does not have access to users, we cannot take action against users who violate these terms. It is the user's responsibility to comply with the rules and regulations applicable in their jurisdiction.</p>
    <p><strong>4. Legal Obligations:</strong> The use of this application must comply with the laws and regulations applicable in your jurisdiction. We are not responsible for the use of this application in violation of applicable laws or regulations.</p>
    <p><strong>5. User Awareness:</strong> By using this application, you acknowledge that you have read, understood, and agreed to this disclaimer. You also acknowledge that you have sufficient knowledge and understanding of blockchain technology, cryptocurrency tokens, and the Solana network.</p>
    <p>Please note that this application is not intended as financial or investment advice, and you should conduct your own research or consult with a financial professional before making investment decisions.</p>
  </div>
  <DisclaimerTos></DisclaimerTos>
</template>

<script>
import DisclaimerTos from './components/DisclaimerTos.vue';
export default {
  name: 'DisclaimerSpl',
  components: {
	DisclaimerTos
  },
}
</script>

<style scoped>
.disclaimer {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}
.disclaimer h1 {
  font-size: 24px;
  margin-bottom: 20px;
}
.disclaimer p {
  margin-bottom: 15px;
}
.disclaimer strong {
  font-weight: bold;
}
</style>
