<!-- App.vue -->

<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
body{
  background-image: linear-gradient(180deg,#f4f7ff,#bde6ea 51.04%,#aecef0);
  background-color: #f4f7ff; /* Warna latar belakang */
  margin:0;
}
</style>
