// src/router/index.js

import { createRouter, createWebHistory } from 'vue-router';
import WallSpl from '../WallSpl.vue'; // Impor komponen utama dari src/App.vue
import HomeSpl from '../HomeSpl.vue'; // Impor komponen utama dari src/App.vue
import DisclaimerSpl from '../AboutSpl.vue'; // Impor komponen tentang dari src/AboutSpl.vue
import BulkMint from '../BulkMint.vue'; // Impor komponen tentang dari src/TokenForm.vue
import PrivacyPolicy from '../PrivacyPolicy.vue'; // Impor komponen tentang dari src/TokenForm.vue
const routes = [
  {
    path: '/',
    name: 'WallSpl',
    component: WallSpl // Gunakan komponen utama sebagai halaman beranda
  },
  {
    path: '/create',
    name: 'Create',
    component: HomeSpl // Gunakan komponen utama sebagai halaman beranda
  },
  {
    path: '/bulkmint',
    name: 'BulkMint',
    component: BulkMint // Gunakan komponen utama sebagai halaman beranda
  },
  {
    path: '/disclaimer',
    name: 'Disclaimer',
    component: DisclaimerSpl // Gunakan komponen tentang sebagai halaman tentang
  },
  {
    path: '/PrivacyPolicy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy // Gunakan komponen tentang sebagai halaman tentang
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
