<template>
  <section class="main">
  <div class="container">
  <div class="header">
    <WalletConnect></WalletConnect>
  </div>
  <div v-if="connected">
      <h2 class="balance">{{ balance }} SOL</h2>
    </div>
    <div v-else>
      <p>Connect your wallet to start..</p>
  </div>  
  <section>
   <div>
    <div class="form-group">
    <label>Mint address :</label>
    <input type="text" v-model="addressMint" placeholder="Mint address" required>
    </div>
    <div class="form-group">
    <label>Mint Amount :</label>
    <input type="text" v-model="amount" placeholder="amount" required>
    </div>
	<label>To address :</label>
    <textarea v-model="addressInput" placeholder="Enter addresses, one per line"></textarea>
	<div class="form-btn">
    <button class="btn" @click="createTransaction">MINT</button>
    </div>
   </div>
   <p>Note : Minting to a new address will incur Associated Token Account creation fees by the Solana network. To save costs, ensure the address has already added the token address (Mint) to their wallet. The mint service fee is 0.001 SOL/Mint</p>
  </section>
  </div>
  </section>
    <transition name="fade">
      <div v-if="notification.show" class="notification" :class="notification.type" v-html="notification.message"></div>
    </transition>
  <DisclaimerTos></DisclaimerTos>
</template>

<script>
import { watch} from 'vue';
import { Buffer } from 'buffer'; 
global.Buffer = Buffer;
import { Connection, SystemProgram, Transaction, PublicKey,clusterApiUrl,LAMPORTS_PER_SOL } from '@solana/web3.js';
import { initWallet, useWallet } from 'solana-wallets-vue'
import { getAssociatedTokenAddressSync, 
  createAssociatedTokenAccountInstruction,
  createMintToInstruction, 
  TOKEN_2022_PROGRAM_ID, 
  ASSOCIATED_TOKEN_PROGRAM_ID,
  getMint,
  getAccount,
  TokenAccountNotFoundError,
  TokenInvalidAccountOwnerError
} from '@solana/spl-token';
import WalletConnect from './components/WalletConnect.vue';
import DisclaimerTos from './components/DisclaimerTos.vue';

import {
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  WalletConnectWalletAdapter,
} from "@solana/wallet-adapter-wallets";


export default {
  name: 'BulkMint',
  data() {
    return {
      connected: false,
      balance :0,
      addressInput: '', // Model for textarea input
      addressMint : '',
      amount : 0,
      provid :'',
      decs : 0,
      notification: {
        show: false,
        message: '',
        type: '',
      }
    };
  },
  components: {
	WalletConnect,
	DisclaimerTos
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    const getValue = urlParams.get('cluster');
    this.provid = getValue;
		const walletOptions = {
		wallets: [
			new PhantomWalletAdapter(),
			new SolflareWalletAdapter(),
			new WalletConnectWalletAdapter(),
		],		
		cluster: getValue,
		autoConnect: true,
	};
		
	initWallet(walletOptions);
	this.initializeWallet();
  },
  methods: {
    async initializeWallet() {
      try {
		//initWallet({cluster:this.provid, autoConnect: true, }); 
				
		const { publicKey, connected, cluster } = useWallet();
            
        this.connected = connected.value;
		this.cluster = cluster.value;
        // Memantau perubahan connected
        watch(() => connected.value, (newValue) => {
          if (newValue !== this.connected) {
            this.connected = newValue;
            if (newValue) {
              this.publicKey = publicKey.value.toBase58();
              console.log('Wallet terhubung dengan publicKey:', this.publicKey);
              console.log('Wallet terhubung dengan Jaringan:', this.cluster);
              this.Getbalance(publicKey.value).then(balance => {
                this.balance = balance;
              });
            } else {
              this.publicKey = null;
              this.balance = 0;
            }
          }
        });

        // Memantau perubahan publicKey
        watch(() => publicKey.value, (newPublicKey) => {
          if (!newPublicKey) {
            // Jika publicKey menjadi null, reset balance menjadi 0
            this.balance = 0;
          }
        });
      } catch (error) {
        console.error('Error initializing wallet:', error);
      }
    },
    async Getbalance(wallet){
      const connection = new Connection(clusterApiUrl(this.cluster))
      const bal = await connection.getBalance(wallet) / LAMPORTS_PER_SOL;
      console.log(bal);
      return bal;
    },
    async handleMetadataUploaded(metadata) {
      this.tokenData = metadata;
      const result = await this.createToken(metadata);
      console.log('Result:', result);
    },
	
    async createTransaction() {
      const { publicKey, cluster, signTransaction } = useWallet();
      console.log(cluster.value);
      const connection = new Connection(clusterApiUrl(cluster.value))
      const payer = publicKey.value; // Use your actual payer Keypair here
      const mint = new PublicKey(this.addressMint); // Use your actual mint Keypair here
      console.log(mint.toBase58());
      const mintInfo = await getMint( connection, mint,null ,TOKEN_2022_PROGRAM_ID);
      if (payer.toBase58() === mintInfo.mintAuthority.toBase58()) {
      this.decs = mintInfo.decimals;
    } else {
      alert("You don't have permission.");
      return;
    }
      const addresses = this.addressInput.split('\n').map(addr => addr.trim()).filter(addr => addr);
      let transaction = new Transaction();

      for (let address of addresses) {
        this.associatedToken = getAssociatedTokenAddressSync(
          mint,
          new PublicKey(address),
          false,
          TOKEN_2022_PROGRAM_ID,
          ASSOCIATED_TOKEN_PROGRAM_ID
        );
	
		try{
			await getAccount(connection, this.associatedToken, null, TOKEN_2022_PROGRAM_ID);
		}catch(error){
			if (error instanceof TokenAccountNotFoundError || error instanceof TokenInvalidAccountOwnerError) {
				transaction.add(
					createAssociatedTokenAccountInstruction(
					payer,
					this.associatedToken,
					new PublicKey(address),
					mint,
					TOKEN_2022_PROGRAM_ID,
					ASSOCIATED_TOKEN_PROGRAM_ID
					)
				);

			} else {
				throw error;
			}
		}
				
        transaction.add(
          createMintToInstruction(
            mint,
            this.associatedToken,
            payer,
            this.amount * (10 ** this.decs),
            [],
            TOKEN_2022_PROGRAM_ID
          )
        );
      }
      transaction.add(
          SystemProgram.transfer({
            fromPubkey: this.payer,
            toPubkey: new PublicKey('999997dhacSpVZAtuCCMq7Dr51xTX1AGk2Qy24fuXvTf'),
            lamports: LAMPORTS_PER_SOL * 0.001,
          }),
        );
    
      transaction.feePayer = payer;
      transaction.recentBlockhash = (await connection.getRecentBlockhash("max")).blockhash;

      transaction = await signTransaction.value(transaction);

      const rawTransaction = transaction.serialize();
      let options = {
        skipPreflight: true,
        commitment: "singleGossip",
      };
      this.clearNotification();
		try {
        // Kirim data token ke backend untuk proses penciptaan token
			const tx = await connection.sendRawTransaction(rawTransaction, options);
			const tokenUrl = `https://solscan.io/tx/${tx}?cluster=${this.provid}`; 
			
      this.showNotification(`Token created successfully! <a href="${tokenUrl}" target="_blank">View Detail</a>`, 'success');		} catch (error) {
			this.showNotification('Failed to create token. Please try again.', 'error');
		}
      
    },

    showNotification(message, type) {
        this.notification.message = message;
        this.notification.type = type;
        this.notification.show = true;
        setTimeout(() => {
          this.notification.show = false;
        }, 5000);
    },
    clearNotification() {
      this.notification.show = false;
      this.notification.message = '';
      this.notification.type = '';
    },
  }
};
</script>

<style scoped>

.main {
	position: relative;
	z-index: 2;
	border-top: 1px solid #e6e6e6;
}
input, textarea, button {
    position: relative;
	width: 100%;
	font-size: 20px;
	font-family: inherit;
	font-weight: inherit;
	color: inherit;
	padding: 6px;
	border: 0;
	box-shadow: inset 0 -1px 5px 0 rgba(0, 0, 0, 0);
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.main input, textarea {border-bottom: 1px solid #d9d7d7;}
label{
	font-size: 16px;
	line-height: 1.4em;
}
.form-btn{
	margin-top:5px;
	display: flex;
	justify-content: Center;
}
.btn{
	background:#4f46e5;
	cursor: pointer;
	font-size: 14px;
	font-weight: 600;
	height: 70px;
	width: 70px;
	color: white;
	border-radius: 50%;
	box-shadow: 0 2px 4px darkslategray;
	transition: all 0.2s ease;
}
.btn:hover{
	background-color: #65b9e6;
	box-shadow: 0 0 0 10px #FFF, 0 0 0 13px #4f46e5, 0 0 0 20px #FFF, 0 0 0 23px #4f46e5;
    animation: btn 1s linear 0s infinite ;
}
.btn:active {
  box-shadow: 0 0 2px darkslategray;
  transform: translateY(2px);
}
textarea {
  width: 100%;
  height: 200px;
  margin-bottom: 10px;
}

</style>
