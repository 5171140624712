<template>
  <div class="privacy-policy">
    <h1>Privacy Policy</h1>
    <p>This application does not collect, use, or store any personal information from users. All user interactions with this application are conducted through Web3 and WalletConnect, ensuring user privacy and information security. As a result, no personal information is collected or stored by this application.</p>
    <p>We are committed to maintaining user privacy and understand the importance of protecting personal information. If you have any further questions about the privacy or security of this application, please feel free to contact us at admin@spltools.eu.org.</p>
  </div>
  <DisclaimerTos></DisclaimerTos>
</template>

<script>
import DisclaimerTos from './components/DisclaimerTos.vue';
export default {
  name: 'PrivacyPolicy',
  components: {
	DisclaimerTos
  },
}
</script>

<style scoped>
.privacy-policy {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  height:100%;
}
.privacy-policy h1 {
  font-size: 24px;
  margin-bottom: 20px;
}
.privacy-policy p {
  margin-bottom: 15px;
}
</style>
