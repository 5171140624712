<template>
  <div class="home">
    <div class="container">
      <h1 class="title">Solana Token Creator</h1>
      <p class="subtitle">Create Tokens on Solana Blockchain with Ease</p>
      <div class="illustration">
        <!--img src="/assets/token-creation.svg" alt="Token Creation Illustration"-->
		<router-link to="/create?cluster=mainnet-beta" class="btn-primary">Start Creating Token</router-link>
      </div>
      <p class="description">Solana Token Creator is a user-friendly DApp that allows you to effortlessly create custom tokens on the Solana blockchain. No coding required!</p>
      <div class="highlights">
        <div class="highlight">
          <img src="/assets/secure-icon.svg" alt="Secure Icon">
          <p class="highlight-text">Secure & Trustworthy</p>
          <p class="highlight-description">Transactions are directly connected to your wallet through Web3, ensuring your security and privacy.</p>
        </div>
        <div class="highlight">
          <img src="/assets/fast-icon.svg" alt="Fast Icon">
          <p class="highlight-text">Fast & Efficient</p>
          <p class="highlight-description">Utilizes the token-2022 program from Solana for quick and efficient token creation.</p>
        </div>
        <div class="highlight">
          <img src="/assets/affordable-icon.svg" alt="Affordable Icon">
          <p class="highlight-text">Fees</p>
          <p class="highlight-description">Creating tokens incurs minimal fees, including Solana network fees and a nominal creation fee of 0.01 SOL.</p>
        </div>
      </div>
      <p class="learn-more">To learn more about the token-2022 program, visit <a href="https://spl.solana.com/token-2022" target="_blank" rel="noopener noreferrer">spl.solana.com</a>.</p>
      
    </div>
  </div>
</template>

<script>
export default {
  name: 'WallSpl',

}
</script>
   

<style scoped>

</style>
