<template>
  <footer class="footer">
   <div class="ft">
    <ul class="filters">
      <li><router-link :to="{ path: '../', query: { cluster: provider }}">Home</router-link></li>
      <li><router-link :to="{ path: '../create', query: { cluster: provider }}">Create</router-link></li>
      <li><router-link :to="{ path: '../bulkmint', query: { cluster: provider }}">Mint</router-link></li>
      <li><router-link :to="{ path: '../PrivacyPolicy', query: { cluster: provider }}">Privacy</router-link></li>
      <li><router-link :to="{ path: '../disclaimer', query: { cluster: provider }}">Disclaimer</router-link></li>
    </ul>
	</div>
  </footer>
</template>


<script>
export default {
	data() {
    return {
      provider: '',
    };
  },
   mounted(){
    const urlParams = new URLSearchParams(window.location.search);
    const getValue = urlParams.get('cluster');
	this.provider= getValue;
  },
};
</script>

<style>
.footer{
display: flex;
justify-content: center;
align-items: center;
background: #280882;
color: #fff;
margin: 0;
padding: 15px;
}
.ft{
max-width:500px;
}
.filters {
	margin: 0;
	padding: 0;
	list-style: none;
	position: relative;
	right: 0;
	left: 0;
}

.filters li {
	display: inline;
}

.filters li a {
  display:inline-block;
  text-decoration:none; 
  
  color:#fff;
  brackground-color:transparent;
  
  padding: .3em 1.2em;
  margin:5px;
      
  background-size: 200% 100%; 
  background-image: linear-gradient(to right, transparent 50%, #4f46e5 50%);
  transition: background-position .3s cubic-bezier(0.19, 1, 0.22, 1) .1s, color .5s ease 0s, background-color .5s ease;
}

.filters li a:hover {
  color:rgba(255, 255, 255, 1);
  background-color: #4f46e5;
  background-position: -100% 100%;
}

.filters li a.selected {
	border-color: #CE4646;
}

@media (max-width: 430px) {
	.footer {
		height: auto;
		padding: 0px;
	}

}

</style>
